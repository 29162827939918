import appStrings from '@/app/utility/string.utility.js';
import DatePicker from 'vue2-datepicker';
import { ModelSelect, BasicSelect } from 'vue-search-select';
import party from '@/app/components/generalLedger/party';
import currency from '@/app/components/admin/currency';
import commonHelper from '@/app/utility/common.helper.utility.js';
import store from '../../../../../store';
import downlaodFiles from '@/app/components/onlineApproval/nfaApproval/addNfaApproval/downloadFiles';
import URL_UTILITY from '@/app/utility/url.utility';
import openKmAuthentication from '@/app/components/onlineApproval/internalWorkFlow/openKmAuthentication';
export default {
  name: 'AddEditMutation',
  components: {
    DatePicker,
    ModelSelect,
    BasicSelect,
    party,
    currency,
    downlaodFiles,
    openKmAuthentication
  },
  props: [
    'selectedMutationHdrId',
    'eventId',
    'wf_inprocess_dtl_id',
    'rowDetails'
  ],
  watch: {
    fmsPrjName: function() {
      if (this.fmsPrjName.value) {
        this.lovFieldName = appStrings.FMSLOV.FMSPROJECT;
        this.lovId = this.fmsPrjName.value;
        // this.getOtherLovByProjectId();
      }
    }
  },
  data() {
    return {
      unsubscribe: null,
      loader: false,
      transferDate: null,
      rentStartDate: null,
      mutationFee: null,
      editMode: false,
      ownerFields: [
        {
          key: 'is_primary',
          label: 'Primary',
          stickyColumn: true
        },
        {
          key: 'unit_customer_type_vset_meaning',
          label: 'Customer Type'
        },
        {
          key: 'customer_name'
        },
        {
          key: 'customer_site'
        },
        {
          key: 'vendor_name'
        },
        {
          key: 'vendor_site'
        },
        {
          key: 'allotment_no'
        },
        {
          key: 'applicant_id'
        },
        {
          key: 'sharing_ratio'
        },
        {
          key: 'active'
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        },
        {
          key: 'sd_paid_amount',
          label: 'SD Paid Amount'
        },
        {
          key: 'sd_actual_paid_amount',
          label: ' SD Actual Paid Amount'
        }
      ],
      ownerData: [],
      addmutationTypeFields: [
        {
          key: 'add',
          stickyColumn: true
        },
        {
          key: 'remove',
          stickyColumn: true
        },
        {
          key: 'is_primary',
          label: 'Primary',
          stickyColumn: true
        },
        {
          key: 'unit_customer_type_vset_meaning',
          label: 'Customer Type'
        },
        {
          key: 'mutation_party_type_vset_meaning',
          label: 'Mutation Party Type'
        },
        {
          key: 'customer_name'
        },
        {
          key: 'customer_site'
        },
        {
          key: 'vendor_name'
        },
        {
          key: 'vendor_site'
        },
        {
          key: 'allotment_no'
        },
        {
          key: 'applicant_id'
        },
        {
          key: 'sharing_ratio'
        },
        {
          key: 'sd_paid_amount',
          label: 'SD Paid Amount'
        },
        {
          key: 'sd_actual_paid_amount',
          label: 'SD Actual Paid Amount'
        },

        {
          key: 'active',
          stickyColumn: true
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        }
      ],
      addmutationTypeData: [
        {
          customer_id: null,
          start_date: null,
          end_date: null,
          customer_site_id: null,
          customer_site: null,
          vendor_name: null,
          vendor_site_id: null,
          fms_mutation_hdr_id: 0,
          fms_mutation_dtl_id: 0,
          mutation_num: null,
          customer_name: null,
          vendor_id: null,
          vendor_site: null,
          sharing_ratio: 0,
          unit_customer_type_vset_meaning: null,
          unit_party_id: null,
          is_primary: false,
          unit_customer_type_vset: null,
          allotment_no: null,
          applicant_id: null,
          mutation_party_type_vset: 'NEW',
          mutation_party_type_vset_meaning: 'New',
          active: true,
          sd_paid_amount: null,
          sd_actual_paid_amount: null
        }
      ],
      documentCheckListFeilds: [
        {
          key: 'pan_card',
          stickyColumn: true
        },
        {
          key: 'pan_card_details'
        },

        {
          key: 'adhar_card',
          stickyColumn: true
        },
        {
          key: 'adhar_card_details'
        },

        {
          key: 'no_objection_certificate',
          stickyColumn: true
        },
        {
          key: 'no_objection_certificate_details',
          label: 'NOC Details'
        }
      ],
      documentCheckListData: [],

      showValueSetModal: false,
      parent_value_set_id: null,
      mutationTypeVset: null,
      mutationTypeVsetMeaning: null,

      fmsMutationHdrId: 0,
      mutationNum: null,
      leName: {
        value: null,
        text: null
      },
      ouName: {
        value: null,
        text: null
      },
      fmsPrjName: {
        value: null,
        text: null
      },
      fmsPrjList: [],
      fmsTowerName: {
        value: null,
        text: null
      },
      fmsTowerList: [],
      fmsFloorName: {
        value: null,
        text: null
      },
      fmsFloorList: [],
      fmsUnitName: {
        value: null,
        text: null
      },
      fmsUnitList: [],
      transferStatus: 'U',
      transferStatusMeaning: 'UNPROCESSED',
      approvalStatus: appStrings.defaultApprovalStatus,
      approvalStatusMeaning: appStrings.defaultApprovalStatus,
      currency: null,
      currencyId: null,
      exchangeRate: 1,
      trxType: null,
      trxTypeDtlId: null,

      keyValue: {
        value: null,
        text: null
      },
      keyValueNoRecord: {
        value: null,
        text: 'no record found'
      },
      selectedTrxType: {
        value: null,
        text: null
      },
      trxTypeList: [],
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      ownershipIndex: null,
      version: 0,
      mutationDate: null,

      showPartyModal: false,
      showCustomerSiteModal: false,
      gridDataSite: [],
      gridFieldsSite: [
        {
          key: 'site_name'
        },
        {
          key: 'site_type'
        },
        {
          key: 'bill_to_address',
          label: 'Site Address'
        },
        {
          key: 'ou_name',
          label: 'Operating Unit'
        },
        {
          key: 'gstn',
          label: 'GSTN'
        }
      ],
      partyType: null,

      isApprovedByCurrrentUser: false,
      isConsultInitiated: false,
      urlSummaryData: null,
      wfDtlId: null,
      notificationSummary: null,
      notificationComments: null,
      approvalHistoryList: [],
      approvalHistoryFields: [
        {
          key: 'approver_name',
          label: 'Name'
        },
        {
          key: 'approval_status',
          label: 'Status'
        },
        {
          key: 'comments',
          label: 'Comments'
        },
        {
          key: 'action_date'
        }
      ],
      showCurrencyModal: false,
      remark: null,
      mutationPartyType: {
        value: null,
        text: null
      },

      showDownloadModal: false,
      downloadItems: [],
      downloadFields: [
        {
          key: 'original_file_name',
          label: 'File Name'
        },
        {
          key: 'doc_version',
          label: 'Version'
        },
        {
          key: 'file_size',
          label: 'Size'
        },
        {
          key: 'comments'
        },
        {
          key: 'created_by'
        },
        {
          key: 'creation_date'
        }
      ],
      downloadDocUrl: URL_UTILITY.getOpenKmDocDeleteUrl,
      totalRows: null,
      currentPage: 1,
      perPage: commonHelper.perPageRecord,
      showOpenKmModal: false,
      menuId: null,
      mutationInvestorData: [
        {
          new_rate: null,
          new_rate_uom_vset: null,
          new_rate_uom_vset_meaning: null
        }
      ],
      mutationInvestorFields: [
        {
          key: 'lease_investor_agreement_no',
          label: 'Lease Investor No.'
        },
        {
          key: 'lease_tenant_agreement_no',
          label: 'Lease Tenant No.'
        },
        {
          key: 'vendor_name'
        },
        {
          key: 'rate'
        },
        {
          key: 'rate_uom_vset_meaning',
          label: 'Rate UOM'
        },
        {
          key: 'billable_area'
        },
        {
          key: 'billable_area_uom_vset_meaning',
          label: 'Billable Area UOM'
        },
        {
          key: 'fms_subunit_name',
          label: 'FMS Sub Unit Name'
        },
        {
          key: 'new_rate'
        },
        {
          key: 'new_rate_uom_vset_meaning',
          label: 'New Rate UOM'
        }
      ],
      invLeaseIndex: null,
      depVsetParam: null,
      newPayload: null
    };
  },
  mounted() {
  if (this.rowDetails) {
      this.getMutationInvestorDetailsById();
    }
    if (this.$route.params.summaryData) {
      this.urlSummaryData = JSON.parse(
        Buffer.from(this.$route.params.summaryData, 'base64').toString('ascii')
      );
    }
    if (this.urlSummaryData) {
      this.wfDtlId = this.urlSummaryData.wf_inprocess_dtl_id;
    } else {
      this.wfDtlId = this.wf_inprocess_dtl_id;
    }

    if (this.selectedMutationHdrId || this.urlSummaryData) {
      this.setMutationHdrId(
        this.selectedMutationHdrId || this.urlSummaryData.FMS_MUTATION_HDR_ID
      );
      if (this.urlSummaryData) {
        this.wfDtlId = this.urlSummaryData.wf_inprocess_dtl_id;
      }
    } else {
      this.editMode = true;
      // this.setDefaultTrasferStatus();
    }
    this.getTxnTypeList();
    this.getCurrency();
    this.getUomClass();
    // this.setDefaultTrasferStatus();
    this.setDefaultPartyMutationType();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'update') {
          if (
            (this.approvalStatus === 'RESUBMIT' &&
              this.transferStatus === 'U') ||
            this.approvalStatus === appStrings.defaultApprovalStatus
          ) {
            this.editMode = true;
          }
        }
        if (actionName === 'save' && this.editMode) {
          this.addEditMutationHdrOwnership();
        }
      }
    });
  },
  methods: {
    getMutationInvestorDetailsById() {
      const payload = {
        fms_mutation_hdr_id: this.rowDetails.fms_mutation_hdr_id,
        fms_unit_id: this.rowDetails.fms_unit_id
      };
      this.loader = true;
      this.$store
        .dispatch('masterData/getMutationInvestorDetailsById', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            if (response.data.data.length > 0) {
              this.mutationInvestorData = response.data.data;
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    hideModal() {
      this.$emit('openCloseMutationDetails', false);
    },
    openValueSetModal(vsetCode, index) {
      this.ownershipIndex = index;
      this.chooseParentVsetId(vsetCode);
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      switch (this.vsetCode) {
        case appStrings.VALUESETTYPE.NFA_FMS_TOWER:
          this.fmsTowerName = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
          this.fmsFloorName = {
            value: null,
            text: null
          };
          this.fmsUnitName = {
            value: null,
            text: null
          };
          break;
        case appStrings.VALUESETTYPE.NFA_FMS_FLOOR:
          this.fmsFloorName = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
          this.fmsUnitName = {
            value: null,
            text: null
          };
          break;
        case appStrings.VALUESETTYPE.NFA_FMS_UNIT:
          this.fmsUnitName = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
          this.fmsFloorName = {
            value: item.floor_id,
            text: item.floor_name
          };
          this.fmsTowerName = {
            value: item.tower_id,
            text: item.tower_name
          };
          this.getOwnershipDetails();
          this.getExistedOwnershipDetails();
          break;
        case appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST:
          this.leName = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
          this.ouName = {
            value: null,
            text: null
          };
          this.fmsPrjName = {
            value: null,
            text: null
          };
          break;
        case appStrings.VALUESETTYPE.OU_LIST:
          this.ouName = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
          break;
        case appStrings.VALUESETTYPE.FMS_PROJECT:
          this.fmsPrjName = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
          this.fmsTowerName = {
            value: null,
            text: null
          };
          this.fmsFloorName = {
            value: null,
            text: null
          };
          this.fmsUnitName = {
            value: null,
            text: null
          };
          break;
        case appStrings.VALUESETTYPE.FMS_TOWER:
          this.fmsTowerName = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
          break;
        case appStrings.VALUESETTYPE.FMS_FLOOR:
          this.fmsFloorName = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
          break;
        case appStrings.VALUESETTYPE.FMS_UNIT:
          this.fmsUnitName = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
          break;
        case appStrings.VALUESETTYPE.MUTATION_TYPE:
          this.mutationTypeVset = item.value_code;
          this.mutationTypeVsetMeaning = item.value_meaning;
          this.setOwnrshpDataForAddDelMutationTyp();
          if (this.mutationTypeVset === 'TRANSFER') {
            if (this.mutationDate) {
              this.addmutationTypeData[0].start_date = this.mutationDate;
            }
          }
          break;
        // case appStrings.VALUESETTYPE.SCHEDULE_STATUS_VSET:
        //   this.transferStatus = item.value_code;
        //   this.transferStatusMeaning = item.value_meaning;
        //   break;
        case appStrings.VALUESETTYPE.APPROVAL_STATUS:
          this.approvalStatus = item.value_code;
          this.approvalStatusMeaning = item.value_meaning;
          break;
        case appStrings.VALUESETTYPE.FMS_PARTY_TYPE:
          this.addmutationTypeData[
            this.ownershipIndex
          ].unit_customer_type_vset_meaning = item.value_meaning;
          this.addmutationTypeData[
            this.ownershipIndex
          ].unit_customer_type_vset = item.value_code;
          break;
        case appStrings.VALUESETTYPE.UOM:
          this.mutationInvestorData[
            this.ownershipIndex
          ].new_rate_uom_vset_meaning = item.value_meaning;
          this.mutationInvestorData[this.ownershipIndex].new_rate_uom_vset =
            item.value_code;
          this.mutationInvestorData = JSON.parse(
            JSON.stringify(this.mutationInvestorData)
          );
          break;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    chooseValueset(name, index) {
      switch (name) {
        case 'legal_entity':
          this.openValueSetModal(appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST);
          break;
        case 'operating_unit':
          this.openValueSetModal(appStrings.VALUESETTYPE.OU_LIST);
          break;
        case 'mutation_type':
          this.openValueSetModal(appStrings.VALUESETTYPE.MUTATION_TYPE);
          break;
        case 'fms_project':
          this.openValueSetModal(appStrings.VALUESETTYPE.FMS_PROJECT);
          break;
        case 'fms_tower':
          this.openValueSetModal(appStrings.VALUESETTYPE.FMS_TOWER);
          break;
        case 'fms_floor':
          this.openValueSetModal(appStrings.VALUESETTYPE.FMS_FLOOR);
          break;
        case 'fms_unit':
          this.openValueSetModal(appStrings.VALUESETTYPE.FMS_UNIT);
          break;
        // case 'transfer_status':
        //   this.openValueSetModal(appStrings.VALUESETTYPE.SCHEDULE_STATUS_VSET);
        //   break;
        case 'approval_status':
          this.openValueSetModal(appStrings.VALUESETTYPE.APPROVAL_STATUS);
          break;
        case 'customer_type':
          this.openValueSetModal(appStrings.VALUESETTYPE.FMS_PARTY_TYPE, index);
          break;
        case 'mutation_party_type':
          this.openValueSetModal(
            appStrings.VALUESETTYPE.MUTATION_PARTY_TYPE,
            index
          );
          break;
        case 'uom':
          this.openValueSetModal(appStrings.VALUESETTYPE.UOM, index);
          break;
      }
    },
    chooseParentVsetId(name) {
      switch (name) {
        case appStrings.VALUESETTYPE.OU_LIST:
        case appStrings.VALUESETTYPE.FMS_PROJECT:
          this.parent_value_set_id = this.leName.value;
          break;
        case appStrings.VALUESETTYPE.UOM:
          this.parent_value_set_id = this.intervalClass[0].detail_id;
          break;
        case appStrings.VALUESETTYPE.NFA_FMS_TOWER:
          this.parent_value_set_id = this.fmsPrjName.value;
          break;
        case appStrings.VALUESETTYPE.NFA_FMS_FLOOR:
          this.parent_value_set_id = this.fmsTowerName.value;
          this.depVsetParam = this.fmsPrjName.value;
          break;
        case appStrings.VALUESETTYPE.NFA_FMS_UNIT:
          this.parent_value_set_id = this.fmsFloorName.value;
          this.depVsetParam = this.fmsPrjName.value;
          break;
        default:
          this.parent_value_set_id = null;
          this.depVsetParam = null;
      }
    },
    setMutationHdrId(id) {
      this.fmsMutationHdrId = id;
      this.getMutationDetailById();
    },
    getMutationDetailById() {
      this.loader = true;
      this.$store
        .dispatch('masterData/getMutationDetailsById', this.fmsMutationHdrId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const result = response.data.data;
            this.fmsMutationHdrId = result.fms_mutation_hdr_id;
            this.mutationNum = result.mutation_num;
            this.mutationTypeVset = result.mutation_type_vset;
            this.mutationTypeVsetMeaning = result.mutation_type_vset_meaning;
            this.mutationDate = result.mutation_date;
            this.transferDate = result.transfer_date;
            this.rentStartDate = result.rent_start_date;
            this.transferStatus = result.transfer_status;
            this.transferStatusMeaning = result.transfer_status_meaning;
            this.approvalStatus = result.approval_status;
            this.approvalStatusMeaning = result.approval_status_meaning;
            this.mutationFee = result.mutation_fee;
            this.currency = result.currency_name;
            this.currencyId = result.currency_id;
            this.exchangeRate = result.exchange_rate;
            this.version = result.version;
            this.menuId = result.menu_id;
            this.trxType = result.trx_type;
            this.trxTypeDtlId = result.trx_type_dtl_id;
            this.selectedTrxType = {
              value: result.trx_type_dtl_id,
              text: result.trx_type
            };
            this.leName = {
              value: result.le_id,
              text: result.le_name
            };
            this.ouName = {
              value: result.ou_id,
              text: result.ou_name
            };
            this.fmsPrjName = {
              value: result.fms_prj_id,
              text: result.fms_prj_name
            };
            this.fmsTowerName = {
              value: result.fms_tower_id,
              text: result.fms_tower_name
            };
            this.fmsFloorName = {
              value: result.fms_floor_id,
              text: result.fms_floor_name
            };
            this.fmsUnitName = {
              value: result.fms_unit_id,
              text: result.fms_unit_name
            };
            if (
              result.fms_mutation_hdr_details &&
              result.fms_mutation_hdr_details.length > 0
            ) {
              this.addmutationTypeData = result.fms_mutation_hdr_details;
            }
            this.remark = result.remark;
            if (this.fmsMutationHdrId > 0) {
              this.getExistedOwnershipDetails();
            }
            this.getTransferMutationDetails();
            this.$emit('setMutationDetails', result);
            if (
              this.$route.name === 'notificationSummary' ||
              this.$route.name === 'dashboard' ||
              this.$route.name === 'workFlowSummary'
            ) {
              this.notificationSummary = true;
              this.getApprovalHistory(this.fmsMutationHdrId);
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getOwnershipDetails() {
      this.loader = true;
      this.$store
        .dispatch('masterData/getUnitOwnerShipDetails', this.fmsUnitName.value)
        .then(response => {
          this.loader = false;
          this.ownerData = response.data.data;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getExistedOwnershipDetails() {
      this.loader = true;
      this.$store
        .dispatch(
          'masterData/getExistedOwnershipDetails',
          this.fmsMutationHdrId
        )
        .then(response => {
          this.loader = false;
          this.ownerData = response.data.data;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getTransferMutationDetails() {
      this.loader = true;
      this.$store
        .dispatch(
          'masterData/getTransferMutationDetails',
          this.fmsMutationHdrId
        )
        .then(response => {
          this.loader = false;
          if (response.data.data.length > 0) {
            this.addmutationTypeData = response.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    setOwnrshpDataForAddDelMutationTyp() {
      if (this.mutationTypeVset === 'ADDDEL') {
        // to create a deep copy of an array && copy by value not by reference
        this.addmutationTypeData = JSON.parse(JSON.stringify(this.ownerData));
        this.addmutationTypeData.forEach(elem => {
          elem.mutation_party_type_vset = 'NEW';
          elem.mutation_party_type_vset_meaning = 'New';
          elem.sharing_ratio = Number(elem.sharing_ratio);
          elem.start_date = this.addmutationTypeData.start_date;
          elem.end_date = this.addmutationTypeData.end_date;
          // elem.sd_actual_paid_amount = this.addmutationTypeData.sd_actual_paid_amount;
          // elem.sd_paid_amount = this.addmutationTypeData.sd_paid_amount;
        });
        // this.addmutationTypeData[0].start_date = this.mutationDate;
      } else {
        this.addmutationTypeData = [
          {
            customer_id: null,
            start_date: null,
            end_date: null,
            customer_site_id: null,
            customer_site: null,
            vendor_name: null,
            vendor_site_id: null,
            fms_mutation_hdr_id: 0,
            fms_mutation_dtl_id: 0,
            mutation_num: null,
            customer_name: null,
            vendor_id: null,
            vendor_site: null,
            sharing_ratio: 0,
            unit_customer_type_vset_meaning: null,
            unit_party_id: null,
            is_primary: false,
            unit_customer_type_vset: null,
            allotment_no: null,
            applicant_id: null,
            mutation_party_type_vset: 'NEW',
            mutation_party_type_vset_meaning: 'New',
            active: true,
            sd_actual_paid_amount: null,
            sd_paid_amount: null
          }
        ];
      }
    },
    selectedTowerFun(towerVal) {
      this.lovFieldName = appStrings.FMSLOV.FMSTOWER;
      this.lovId = towerVal.value;
      this.fmsTowerName = towerVal;
      this.getOtherLovByProjectId();
    },
    selectedFloorFun(floorVal) {
      this.lovFieldName = appStrings.FMSLOV.FMSFLOOR;
      this.fmsFloorName = floorVal;
      this.lovId = floorVal.value;
      this.getOtherLovByProjectId();
    },
    selectedUnitFun(unitVal) {
      this.lovFieldName = appStrings.FMSLOV.FMSUNIT;
      this.fmsUnitName = unitVal;
      this.lovId = unitVal.value;
      this.getOtherLovByProjectId();
      this.getExistedOwnershipDetails();
      this.getOwnershipDetails();
    },
    // getOtherLovByProjectId() {
    //   if (this.editMode) {
    //     this.loader = true;
    //     const payload = {
    //       lovFieldName: this.lovFieldName,
    //       lovId: this.lovId
    //     };
    //     this.$store
    //       .dispatch('receipt/getOtherLovByLovFieldAndId', payload)
    //       .then(response => {
    //         this.loader = false;
    //         if (response && response.data && response.data.data) {
    //           const results = response.data.data;
    //           if (this.lovFieldName === appStrings.FMSLOV.FMSPROJECT) {
    //             this.getTowerList(results.towers);
    //             this.getFloorList(results.floors);
    //             this.getUnitList(results.units);
    //           } else if (this.lovFieldName === appStrings.FMSLOV.FMSTOWER) {
    //             this.getFloorList(results.floors);
    //             this.getUnitList(results.units);
    //           } else if (this.lovFieldName === appStrings.FMSLOV.FMSFLOOR) {
    //             this.getTowerList(results.towers);
    //             this.getUnitList(results.units);
    //           } else if (this.lovFieldName === appStrings.FMSLOV.FMSUNIT) {
    //             this.getTowerList(results.towers);
    //             this.getFloorList(results.floors);
    //           }
    //         }
    //       })
    //       .catch(error => {
    //         this.loader = false;
    //         alert(error.message);
    //       });
    //   }
    // },
    // getTowerList(towerList) {
    //   if (towerList && towerList.length) {
    //     const valueAndTextArr = towerList.map(type => {
    //       return {
    //         value: type.tower_id,
    //         text: type.tower_name
    //       };
    //     });
    //     this.fmsTowerList = valueAndTextArr;
    //     this.fmsTowerName =
    //       valueAndTextArr.length === 1 ? valueAndTextArr[0] : this.keyValue;
    //   } else {
    //     this.fmsTowerName = this.keyValueNoRecord;
    //     this.fmsTowerList = [
    //       {
    //         value: null,
    //         text: 'no record found'
    //       }
    //     ];
    //   }
    // },
    // getFloorList(floorList) {
    //   if (floorList && floorList.length) {
    //     const valueAndTextArr = floorList.map(type => {
    //       return {
    //         value: type.floor_id,
    //         text: type.floor_name
    //       };
    //     });
    //     this.fmsFloorList = valueAndTextArr;
    //     this.fmsFloorName =
    //       valueAndTextArr.length === 1 ? valueAndTextArr[0] : this.keyValue;
    //   } else {
    //     this.fmsFloorName = this.keyValueNoRecord;
    //     this.fmsFloorList = [
    //       {
    //         value: null,
    //         text: 'no record found'
    //       }
    //     ];
    //   }
    // },
    // getUnitList(unitList) {
    //   if (unitList && unitList.length) {
    //     const valueAndTextArr = unitList.map(type => {
    //       return {
    //         value: type.unit_id,
    //         text: type.unit_name
    //       };
    //     });
    //     this.fmsUnitList = valueAndTextArr;
    //     this.fmsUnitName =
    //       valueAndTextArr.length === 1 ? valueAndTextArr[0] : this.keyValue;
    //   } else {
    //     this.fmsUnitName = this.keyValueNoRecord;
    //     this.fmsUnitList = [
    //       {
    //         value: null,
    //         text: 'no record found'
    //       }
    //     ];
    //   }
    // },
    getTxnTypeList() {
      const moduleId = this.$store.state.shared.moduleId;
      this.$store.dispatch('leases/getTxnTypeList', moduleId).then(response => {
        if (response.status === 200) {
          const results = response.data.data;
          const typeList = results.map(type => {
            return {
              value: type.trx_type_dtl_id,
              text: type.trx_desc
            };
          });
          this.trxTypeList = typeList;
          this.selectedTrxType = this.trxTypeList[3];
        }
      });
    },
    setDefaultOwnershipData() {
      this.addmutationTypeData = [
        {
          customer_id: null,
          start_date: null,
          end_date: null,
          customer_site_id: null,
          customer_site: null,
          vendor_name: null,
          vendor_site_id: null,
          fms_mutation_hdr_id: 0,
          fms_mutation_dtl_id: 0,
          mutation_num: null,
          customer_name: null,
          vendor_id: null,
          vendor_site: null,
          sharing_ratio: 0,
          unit_customer_type_vset_meaning: null,
          unit_party_id: null,
          is_primary: false,
          unit_customer_type_vset: null,
          allotment_no: null,
          applicant_id: null,
          mutation_party_type_vset: 'NEW',
          mutation_party_type_vset_meaning: 'New',
          active: true,
          sd_actual_paid_amount: null,
          sd_paid_amount: null
        }
      ];
    },
    getCurrency() {
      const payload = {
        currentPage: 0,
        perPage: 10,
        search: 'inr'
      };
      this.$store
        .dispatch('currency/getCurrencyPagination', payload)
        .then(response => {
          const results = response.data.data.page;
          if (results) {
            this.currency = results[0].currency_code;
            this.currencyId = results[0].currency_id;
          }
        });
    },
    addEditMutationInvestorLease() {
      const mutationInvLease = this.mutationInvestorData.map(elem => {
        return {
          fms_mutation_hdr_id: this.rowDetails.fms_mutation_hdr_id,
          mutation_investor_id: elem.mutation_investor_id,
          lease_investor_agreement_hdr_id: elem.lease_investor_agreement_hdr_id,
          new_rate: elem.new_rate,
          new_rate_uom_vset: elem.new_rate_uom_vset
        };
      });
      const payload = {
        mutation_inv_lease: mutationInvLease ? mutationInvLease : null
      };
      this.loader = true;
      this.$store
        .dispatch('masterData/addEditMutationInvestorLease', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.editMode = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    addEditMutationHdrOwnership() {
      const mutationDetails = this.addmutationTypeData.map(elem => {
        return {
          fms_mutation_dtl_id: elem.fms_mutation_dtl_id,
          mutation_party_type_vset: elem.mutation_party_type_vset,
          unit_party_id: elem.unit_party_id,
          customer_id: elem.customer_id,
          customer_site_id: elem.customer_site_id,
          is_primary: elem.is_primary ? true : false,
          applicant_id: elem.applicant_id,
          vendor_id: elem.vendor_id,
          vendor_site_id: elem.vendor_site_id,
          sharing_ratio: elem.sharing_ratio,
          unit_customer_type_vset: elem.unit_customer_type_vset,
          allotment_no: elem.allotment_no,
          active: elem.active,
          start_date: elem.start_date,
          end_date: elem.end_date,
          sd_actual_paid_amount: elem.sd_actual_paid_amount,
          sd_paid_amount: elem.sd_paid_amount
        };
      });
      const ownDtls = this.ownerData.map(elem => {
        return {
          fms_mutation_dtl_id: elem.fms_mutation_dtl_id,
          mutation_party_type_vset: elem.mutation_party_type_vset,
          unit_party_id: elem.unit_party_id,
          customer_id: elem.customer_id,
          customer_site_id: elem.customer_site_id,
          is_primary: elem.is_primary ? true : false,
          applicant_id: elem.applicant_id,
          vendor_id: elem.vendor_id,
          vendor_site_id: elem.vendor_site_id,
          sharing_ratio: elem.sharing_ratio,
          unit_customer_type_vset: elem.unit_customer_type_vset,
          allotment_no: elem.allotment_no,
          active: elem.active,
          start_date: elem.start_date,
          end_date: elem.end_date,
          sd_actual_paid_amount: elem.sd_actual_paid_amount,
          sd_paid_amount: elem.sd_paid_amount
        };
      });
      if(this.fmsMutationHdrId === 0){
         this.newPayload = mutationDetails
      } else {
         this.newPayload = [...ownDtls, ...mutationDetails];
        }
      const payload = {
        approval_status: this.approvalStatus,
        currency_id: this.currencyId,
        exchange_rate: this.exchangeRate,
        fms_mutation_hdr_id: this.fmsMutationHdrId,
        fms_unit_id: this.fmsUnitName.value,
        le_id: this.leName.value,
        menu_id: this.$store.state.shared.menuId,
        mutation_date: this.mutationDate,
        mutation_fee: parseFloat(this.mutationFee.replaceAll(',', '')),
        mutation_type_vset: this.mutationTypeVset,
        ou_id: this.ouName.value,
        transfer_date: this.transferDate,
        transfer_status: this.transferStatus,
        version: this.version,
        trx_type_dtl_id: this.selectedTrxType.value,
        rent_start_date: this.rentStartDate,
        remark: this.remark,
        fms_mutation_hdr_details: this.newPayload
      };
      this.loader = true;
      this.$store
        .dispatch('masterData/addEditMutationHdrOwnership', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.fmsMutationHdrId = response.data.data.fms_mutation_hdr_id;
            this.mutationNum = response.data.data.mutation_num;
            this.trxType = response.data.data.trx_type;
            // this.getOwnershipDetails();
            this.getExistedOwnershipDetails();
            this.getTransferMutationDetails();
            this.$emit('setMutationDetailsId', this.fmsMutationHdrId);
            this.editMode = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    getUomClass(index) {
      this.ownershipIndex = index;
      this.$store
        .dispatch('template/getLOVBySetType', 'UOM_CLASS')
        .then(response => {
          if (response.status === 200) {
            const filterResult = response.data.data;
            const getValueAndText = filterResult.map(type => {
              return {
                value: type.value_code,
                text: type.value_meaning,
                detail_id: type.value_set_dtl_id
              };
            });
            this.intervalClass = getValueAndText.filter(
              item => item.value === 'AREA'
            );
          }
        });
    },
    showHidePartyModal(flag, index, PARTYTYPE) {
      this.showPartyModal = flag;
      this.ownershipIndex = index;
      this.partyType = PARTYTYPE;

      this.setCustCode = setTimeout(() => {
        this.eventBus.$emit('userType', this.partyType);
      }, 0);
    },
    getSelectedPartyCustomer(item) {
      if (this.partyType === 'CUST') {
        this.addmutationTypeData[this.ownershipIndex].customer_name =
          item.party_name;
        this.getCustomerByPartyId(item.party_id);
        this.addmutationTypeData[this.ownershipIndex].customer_site = null;
        this.addmutationTypeData[this.ownershipIndex].customer_site_id = null;
      } else if (this.partyType === 'VEND') {
        this.addmutationTypeData[this.ownershipIndex].vendor_name =
          item.party_name;
        this.getVendorIdByPartyId(item.party_id);
        this.addmutationTypeData[this.ownershipIndex].vendor_site = null;
        this.addmutationTypeData[this.ownershipIndex].vendor_site_id = null;
      }
      this.showPartyModal = false;
    },
    getCustomerByPartyId(partyId) {
      this.$store
        .dispatch('leases/getCustomerByPartyId', partyId)
        .then(response => {
          if (response.status === 200) {
            this.addmutationTypeData[this.ownershipIndex].customer_id =
              response.data.data.customer_id;
          }
        });
    },
    getVendorIdByPartyId(partyId) {
      this.$store
        .dispatch('leaseSchedule/getVendorIdByPartyId', partyId)
        .then(response => {
          if (response.status === 200) {
            this.addmutationTypeData[this.ownershipIndex].vendor_id =
              response.data.data;
          }
        });
    },
    getCustomerSiteByPartyId(index) {
      this.ownershipIndex = index;
      this.gridDataSite = [];
      const payload = {
        customerId: this.addmutationTypeData[this.ownershipIndex].customer_id
      };
      this.loader = true;
      this.$store
        .dispatch('party/getCustomerSiteById', payload)
        .then(response => {
          this.loader = false;
          this.gridDataSite = response.data.data.customer_site_lists;
          this.showCustomerSiteModal = true;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getVendorSitesByVendorId(index) {
      this.ownershipIndex = index;
      this.gridDataSite = [];
      const vendorId = this.addmutationTypeData[this.ownershipIndex].vendor_id;
      this.loader = true;
      this.$store
        .dispatch('leaseSchedule/getVendorSiteByVendorId', vendorId)
        .then(response => {
          this.loader = false;
          this.gridDataSite = response.data.data.vendor_sites;
          this.showCustomerSiteModal = true;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectedSite(item) {
      if (this.partyType === 'CUST') {
        this.addmutationTypeData[this.ownershipIndex].customer_site =
          item.site_name;
        this.addmutationTypeData[this.ownershipIndex].customer_site_id =
          item.customer_site_id;
      } else if (this.partyType === 'VEND') {
        this.addmutationTypeData[this.ownershipIndex].vendor_site =
          item.site_name;
        this.addmutationTypeData[this.ownershipIndex].vendor_site_id =
          item.vendor_site_id;
      }
      this.showCustomerSiteModal = false;
    },
    showHideCustomerSiteModal(flag) {
      this.showCustomerSiteModal = flag;
    },
    addingsnewRow() {
      if (this.editMode) {
        this.addmutationTypeData.push({
          customer_id: null,
          start_date: this.mutationDate,
          end_date: null,
          customer_site_id: null,
          customer_site: null,
          vendor_name: null,
          vendor_site_id: null,
          fms_mutation_hdr_id: 0,
          fms_mutation_dtl_id: 0,
          mutation_num: null,
          customer_name: null,
          active: true,
          vendor_id: null,
          vendor_site: null,
          sharing_ratio: 0,
          unit_customer_type_vset_meaning: null,
          unit_party_id: null,
          is_primary: false,
          unit_customer_type_vset: null,
          allotment_no: null,
          applicant_id: null,
          mutation_party_type_vset: 'NEW',
          mutation_party_type_vset_meaning: 'New',
          sd_actual_paid_amount: null,
          sd_paid_amount: null
        });
      }
    },
    deleteTransferDetails(mutationDtlId, index) {
      this.loader = true;
      this.$store
        .dispatch('masterData/deleteTransferDetails', mutationDtlId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.addmutationTypeData.splice(index, 1);
            if (this.addmutationTypeData.length === 0) {
              this.addingsnewRow();
            }
            this.editMode = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    removingsRow(index) {
      if (this.addmutationTypeData[index].fms_mutation_dtl_id) {
        this.deleteTransferDetails(
          this.addmutationTypeData[index].fms_mutation_dtl_id,
          index
        );
      } else if (this.addmutationTypeData.length > 1) {
        this.addmutationTypeData.splice(index, 1);
      }
    },
    getApprovalHistory(fmsMutationHdrId) {
      this.loader = true;
      this.$store.dispatch('auth/getUserDetail').then(response => {
        if (response.status === 200) {
          this.userInfo = response.data.data;
          const payload = {
            approvalStatus: 'Y',
            nfaHdrId: fmsMutationHdrId,
            eventId: this.urlSummaryData
              ? this.urlSummaryData.wf_event_id
              : this.eventId
          };
          this.$store
            .dispatch('intWorkflow/getApprovalList', payload)
            .then(response => {
              this.loader = false;
              if (response.status === 200) {
                this.approvalHistoryList = response.data.data;
                const findCurrentUser = this.approvalHistoryList.find(
                  user =>
                    user.approver_employee_id === this.userInfo.employee_id &&
                    (user.approval_status === 'INPROCESS' ||
                      user.approval_status === 'CONSULT_INITIATED')
                );
                this.isApprovedByCurrrentUser = findCurrentUser ? true : false;
                const findConsultInitUser = this.approvalHistoryList.find(
                  data => data.approval_status === 'CONSULT_INITIATED'
                );
                this.isConsultInitiated = findConsultInitUser ? true : false;
              }
            })
            .catch(() => {
              this.loader = false;
            });
        }
      });
    },
    changeStatus(status) {
      this.loader = true;
      const payload = [
        {
          event_id: this.urlSummaryData
            ? this.urlSummaryData.wf_event_id
            : this.eventId,
          notification_comments: this.notificationComments,
          notification_status: status,
          wf_inprocess_dtl_id: this.wfDtlId,
          temp_approved_amount: 0
        }
      ];
      this.$store
        .dispatch('intWorkflow/approvalReject', { approval_requests: payload })
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.getMutationDetailById();
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    validateActiveSharingRatio(index) {
      let total = 0;
      const filterData = this.addmutationTypeData.filter(elem => elem.active);
      filterData.forEach(item => {
        total += parseFloat(item.sharing_ratio);
      });
      if (total > 100) {
        this.showAlert = true;
        this.isSuccess = false;
        this.responseMsg = 'Sharing Ratio cannot be greater than 100!';
        this.addmutationTypeData[index].active = false;
      }
    },
    validateSharingRatio(row) {
      if (typeof row.sharing_ratio !== 'number') {
        row.sharing_ratio = 0;
      }
      let sum = 0;
      this.addmutationTypeData.forEach(item => {
        if (item.active) {
          sum += parseFloat(item.sharing_ratio);
          if (sum > 100) {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = 'Sharing Ratio cannot be greater than 100!';
            sum = 0;
            row.sharing_ratio = 0;
          }
        }
      });
    },
    showHideCurrencyModal(flag) {
      this.showCurrencyModal = flag;
    },
    getSelectedCurrency(item) {
      this.currencyId = item.currency_id;
      this.currency = item.currency_code;
      this.showCurrencyModal = false;
    },
    setDefaultTrasferStatus() {
      const receiptType = commonHelper.getValuesetData(
        appStrings.VALUESETTYPE.SCHEDULE_STATUS_VSET
      );
      receiptType.then(key => {
        const result = key.find(elem => elem.value_code === 'U');
        this.transferStatus = result.value_code;
        this.transferStatusMeaning = result.value_meaning;
      });
    },
    setDefaultPartyMutationType() {
      const partyMutType = commonHelper.getValuesetData(
        appStrings.VALUESETTYPE.MUTATION_PARTY_TYPE
      );
      partyMutType.then(key => {
        const result = key.find(elem => elem.value_code === 'EXISTING');
        this.mutationPartyType = {
          value: result.value_code,
          text: result.value_meaning
        };
      });
    },
    getUploadedDocDtl() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        menu_id: store.state.shared.menuId
          ? store.state.shared.menuId
          : this.urlSummaryData
          ? this.urlSummaryData.wf_menu_id
          : this.menuId,
        sub_menu_id: 0,
        record_id: this.fmsMutationHdrId
          ? this.fmsMutationHdrId
          : this.urlSummaryData.FMS_MUTATION_HDR_ID
      };
      this.$store
        .dispatch('intWorkflow/getOpenKmDocDtl', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            if (response.data.data.page.length > 0) {
              const docList = response.data.data.page;
              this.downloadItems = docList.map(data => {
                data.file_size = this.formatBytes(data.file_size);
                return data;
              });
            }
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return '0 Bytes';
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    },
    checkOpenKmPasswordSaved() {
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/checkSavedPassword')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            if (response.data.data) {
              this.getOpenKmAuthToken();
            } else {
              this.showHideOpenKmModal(true);
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getOpenKmAuthToken() {
      const payload = {
        password: null,
        saved_password_flag: true,
        user_name: null
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/openKmAuthenticate', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.openkmAuthToken = response.data.data.token;
            this.downloadFiles(true);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideOpenKmModal(flag) {
      this.showOpenKmModal = flag;
    },
    setOpenKmToken(token) {
      this.showHideOpenKmModal(false);
      this.openkmAuthToken = token;
      this.downloadFiles(true);
    },
    downloadFiles(flag) {
      this.showDownloadModal = flag;
      this.getUploadedDocDtl();
    },
    disabledDates(date) {
      const restrictedDate = new Date(this.transferDate);
      restrictedDate.setHours(0, 0, 0, 0);
      return date < restrictedDate;
    },
    startDateFromMutationDate(date) {
      for (const key in this.addmutationTypeData) {
        this.addmutationTypeData[key].start_date = date;
      }
    },
    clearVsetValues(vsetCode, index) {
      if (vsetCode === 'legal_entity') {
        this.leName = {
          value: null,
          text: null
        };
        this.ouName = {
          value: null,
          text: null
        };
        this.fmsPrjName = {
          value: null,
          text: null
        };
      } else if (vsetCode === 'operating_unit') {
        this.ouName = {
          value: null,
          text: null
        };
      } else if (vsetCode === 'fms_project') {
        this.fmsPrjName = {
          value: null,
          text: null
        };
        this.fmsTowerName = {
          value: null,
          text: null
        };
        this.fmsFloorName = {
          value: null,
          text: null
        };
        this.fmsUnitName = {
          value: null,
          text: null
        };
      } else if (vsetCode === this.fmsTowerName.value) {
        this.fmsTowerName = {
          value: null,
          text: null
        };
        this.fmsFloorName = {
          value: null,
          text: null
        };
        this.fmsUnitName = {
          value: null,
          text: null
        };
      } else if (vsetCode === this.fmsFloorName.value) {
        this.fmsFloorName = {
          value: null,
          text: null
        };
        this.fmsUnitName = {
          value: null,
          text: null
        };
      } else if (vsetCode === this.fmsUnitName.value) {
        this.fmsUnitName = {
          value: null,
          text: null
        };
      } else if (vsetCode === 'mutation_type') {
        this.mutationTypeVset = null;
        this.mutationTypeVsetMeaning = null;
      } else if (vsetCode === 'approval_status') {
        this.approvalStatus = null;
        this.approvalStatusMeaning = null;
      } else if (vsetCode === 'customer_type') {
        this.addmutationTypeData[index].unit_customer_type_vset_meaning = null;
        this.addmutationTypeData[index].unit_customer_type_vset = null;
      }
    },
    checkNumber(item) {
      const tempVal = parseInt(item.sd_actual_paid_amount);
      if (tempVal > 0) {
        if (tempVal >= parseInt(item.sd_paid_amount)) {
          item.sd_actual_paid_amount = null;
          alert(
            'SD Actual Paid Amount Should not be greater than SD Paid Amount'
          );
        }
      }
    },
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
